
import AuthMixin from '@/mixins/AuthMixin';
import { VerifyResponse } from '@/services/management/CustomerController';

export default {
  mixins: [AuthMixin],
  data() {
    return {
      step: 0,
      email: '',
      code: '',
      username: '',
      admin: false,
      tenant: ''
    };
  },
  created() {
    const email = this.$router.currentRoute.query.email;
    const code = this.$router.currentRoute.query.code;
    const invited = this.$router.currentRoute.query.invited;
    if (!email || !code) {
      return this.$router.push({ name: 'auth:signup' }).catch(_ => {});
    }
    this.email = email.toString();
    this.code = code.toString();
    this.invited = invited;
    this.admin = !invited;
    if (invited) {
      this.step = 1;
    }
  },
  methods: {
    handleVerification(res) {
      this.step = 1;
      this.email = res.email;
      this.admin = res.admin;
      this.username = res.username;
      this.tenant = res.email.split('@')[1];
    }
  }
};
